const applicantSignInPolicy = 'B2C_1_signupsignin';
// const agentSignInPolicy = 'B2C_1_agentsignupsignin';
const agentSignInPolicy = 'B2C_1A_signin_with_roles_ONLYLOCAL-FORCERESET';//'B2C_1A_signin_with_roles_ONLYLOCAL-FORCERESET'; // B2C_1A_signin_with_roles // B2C_1A_signin_with_roles_ADDLOCAL-FORCERESET
const resetPasswordPolicy = 'B2C_1_resetpassword';
const azureInstance = `https://login.microsoftonline.com/tfp`;
const azureInstance_v2 = `https://IAPDEVB2C.b2clogin.com/tfp`;
const azureApplicantCallbackUrl = 'application';
const azureAgentCallbackUrl = 'agent';
const ssoEnabled = true;

const googleTagManagerId = 'GTM-D95P';
// const googleTagManagerId = '';
export const commonEnv = {
  applicantSignInPolicy,
  agentSignInPolicy,
  azureInstance,
  azureInstance_v2,
  azureApplicantCallbackUrl,
  azureAgentCallbackUrl,
  googleTagManagerId,
  resetPasswordPolicy,
  ssoEnabled
  
};
